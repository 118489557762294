
import { Endpoint } from "../constants/API";
import http from "./http"


export const requestValidateUserQr = async(token) => {
    console.log({token})
    const data={
        tokenqr:token
    }
    try {
        const resp = await http('post',Endpoint.validateUserQr,data)
        return resp
    } catch (error) {
        console.log(error)
        return { success: false, data: null, error, errors: 'Error de conexión, inténtelo más tarde' };
    }
}