import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import VerifyPage from './pages/VerifyPage';


const App=()=> {
  return (
    <Router>
        <Routes>
          <Route path="/usuario/:id" element={<VerifyPage/>} />
          <Route path="/*" element={<Navigate to='/usuario/novalido'/>}/>
        </Routes>
    </Router>
  );
}

export default App;
