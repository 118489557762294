

const http = async (method, url, data, contentType='json',timeout = 20000)=>{
    
    const token = null;
    const headers = {};
    let body;

    if(contentType === 'form-data'){
        body = data;
    }else{
        headers["Accept"] = "application/json";
        headers["Content-Type"] = "application/json";
        body = JSON.stringify(data);
    }

    
    
    if (token) {
        headers["Authorization"] =`Bearer ${ token }`;
    }

    const errorResponse={
        state: 500,
        success:false,
        errors: 'Error de conexión, inténtelo más tarde'
    }

    return Promise.race([
        new Promise((resolve, reject) => {
                fetch(url, {
                    method,
                    headers,
                    body
                }).then(
                    response => resolve(response.json()),
                    err => reject(errorResponse),
                )}),
        new Promise((_, reject) => setTimeout(() => 
        reject(errorResponse), timeout))
    ]);
}
export default http;
