import 'minireset.css';
import '../App.css';
import check from '../assets/img/check.svg';
import logoUa from '../assets/img/faviconUA.png';
import logoUni from '../assets/img/logo-acreditacion.png';
import { useParams } from 'react-router-dom';
import { requestValidateUserQr } from '../services/requestValidateUserQr';
import { useState, useEffect } from 'react';
import Loading from '../components/Loading';
const VerifyPage = () => {

    const { id } = useParams();

    const [data, setData] = useState('')
    const [error, setError] = useState(false)
    const [msgError, setMsgError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [date, setDate] = useState('')
    const [rol, setRol] = useState('')
    const [fecha, setFecha] = useState('')

    useEffect(() => {
        obtenerFechaEnEspanol()
        getUser();
    },[])

   
    const obtenerFechaEnEspanol=()=> {
        const opciones = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const fecha = new Date();
        const fechaFormateada = new Intl.DateTimeFormat('es-ES', opciones).format(fecha);
        console.log(fechaFormateada)
        setDate(fechaFormateada)
    }

    const getUser = async () => {
        console.log('entroo')
        setIsLoading(true)
        try {                
                const resp = await requestValidateUserQr(id)
                console.log({resp})
                if (resp.success === true) {
                    console.log(resp.usuario)
                    setData(resp.usuario)
                    setRol(resp.rol)
                    setFecha(resp.fecha)
                    
                } else {
                    // setData(resp)
                    setMsgError(resp.errors)
                    setError(true)
                }
            
            setIsLoading(false)

        } catch (error) {
            console.log({ error })
        }
    }


    return (
        <div className="App">

            {isLoading && !data? (
                <Loading />
            ) : (
                error?
                (
                    <div>
                        <img src={logoUni} className='imagenUni' alt="" />

                        <div className="content">
                            <h1 style={{color: '#1f3d80', fontWeight: 'bold' }}>Mensaje :</h1>
                            <h2 style={{color: '#000' }}>¡{msgError}!</h2>
                        </div>
                    </div>
                ):
                (
                    <div>
                        <img src={logoUni} className='imagenUni' alt="" />

                        <div className="container">
                            <div className="content-grey">
                                <img src={check} className='img-check' alt="check" />
                                <h1 style={{color: '#1f3d80', fontWeight: 'bold' }}>¡Verificación exitosa!</h1>
                                {/* <p style={{color: '#000', textAlign: 'justify'}}>Este documento digital fue certificado a través de la Universidad del Atlántico, y su información es totalmente válida.</p> */}
                            </div>

                            <div className='card'>
                                <div className='card-body'>
                                    <div>
                                        <img src={data.imgPerfil} className='imagen' alt="foto" />
                                    </div>
                                    <p>{`La persona portadora de este documento se encuentra registrada en nuestra base de datos.`}</p>
                                </div>
                            </div>

                            <div className='card'>
                                <div className='card-body'>
                                    <table class="table">
                                        <tr>
                                            <td>Nombre:</td> 
                                        </tr>
                                        <tr>
                                            <td style={{background: '#E7E5E6', fontWeight: 'bold'}}>{`${data.nombre} ${data.segundo_nombre ? data.segundo_nombre : ''} ${data.apellido} ${data.segundo_apellido ? data.segundo_apellido : ''}`}</td> 
                                        </tr>
                                        <tr>
                                            <td>Rol:</td> 
                                        </tr>
                                        <tr>
                                            <td style={{background: '#E7E5E6', fontWeight: 'bold'}}>{`${rol}`}</td> 
                                        </tr>
                                        <tr>
                                            <td>Documento de identidad:</td> 
                                        </tr>
                                        <tr>
                                            <td style={{background: '#E7E5E6', fontWeight: 'bold'}}>{`${data.documento}`}</td> 
                                        </tr>
                                        <tr>
                                            {
                                                rol==='ESTUDIANTE MATRICULADO'?
                                                <td>Periodo académico:</td> 
                                                :rol==='GRADUADO'?
                                                <td>Periodo de graduación:</td> 
                                                :<td>Periodo de ingreso:</td> 
                                            }
                                
                                        </tr>
                                        <tr>
                                            <td style={{background: '#E7E5E6', fontWeight: 'bold'}}>{`${fecha}`}</td> 
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div className='card'>
                                {/* <div className='card-header'>
                                    <div>
                                        <img src={logoUa} className='img-check' alt="foto" />
                                    </div>
                                    <div>
                                        <p style={{color: '#1f3d80', fontWeight: 'bold' }}>Uniatlantico</p>
                                        <a href='https://www.uniatlantico.edu.co/' target="_blank">Mas información</a>
                                    </div>
                                </div> */}
                                <div className='card-body'>
                                    <div> <p style={{color: '#1f3d80', fontWeight: 'bold', textAlign: 'center' }}>Certificado el: </p>  <p style={{color: '#000', textAlign: 'center'}}>{`${date}`}</p></div>
                                    <div>
                                        <img src={check} className='img-check' alt="foto" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )} 
        </div>
    );
}

export default VerifyPage;